import React, { useEffect, useRef, useState } from 'react';
import Classes from '../../containers/LoanPrograms/index.module.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { NavLink, Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import BlueButton from '../../components/BlueButton';
import ResourcesMenu from '../../components/ResourcesMenu';
import HowItWorks from '../../components/HowItWorks';
import WhiteButton from '../../components/WhiteButton';
import { Helmet } from 'react-helmet';


const NavWrapper = styled.div`
.nav-pills .nav-link.active, .nav-pills .show>.nav-link{
    color: #00AAE4;
    background-color: transparent;
    // border-bottom:1px solid #3074b7;
    padding: 0.8rem 1rem;
    border-radius: 0!important;

}
.nav-link {
    display: block;
    font-size: 20.1px;
    font-weight: 800;
    padding: 0.8rem 1rem;
    color: #083F88;
    border-radius: 0!important;
}

@media(min-width: 1200px)and (max-width:1400px) {
    .nav-link {
        font-size: 18.1px;
    }
}

@media(min-width: 992px)and (max-width:1199.7px) {
    .nav-link {
        font-size: 15.1px;
        font-weight: 900;
    }
}

@media(min-width: 768px)and (max-width:991.7px) {
    .nav-link {
        font-size: 12.7px;
        padding: 0.5rem 0.5rem;
        font-weight: 900;
    }
    .nav-pills .nav-link.active, .nav-pills .show>.nav-link{
        padding: 0.5rem 0.5rem;
    }
}

@media(max-width:767px){
    .nav-link {
        font-size: 15.5px;
        padding: 0.5rem 0.5rem;
    }
    .nav-pills .nav-link.active, .nav-pills .show>.nav-link{
        padding: 0.5rem 0.5rem;
    }
}

`

const routeKeys = {
    conventional: "conventional",
    fha: "fha",
    investment: "investment",
    jumbo: "jumbo",
    renovation: "renovation",
    refinancing: "refinancing",
    reverse: "reverse",
    va: "va",
    dscrheloc: "dscrheloc"
}

const LoanPrograms = ({ history }) => {
    const [key, setKey] = useState('dscrheloc');
    const imgCDNURL = process.env.REACT_APP_LOWRATES_RESOURCES;
    const [navItemClicked, setNavItemClicked] = useState(false);
    let { eventKey } = useParams();
    const mainContentRef = useRef(null);

    useEffect(() => {
        if (mainContentRef.current) {
            window.scrollTo({
                top: mainContentRef.current.offsetTop,
                behavior: "smooth"
            });
        }
        setNavItemClicked(false);
    }, []);

    const handleNavItemClick = (selectedKey) => {
        setKey(selectedKey);
        setNavItemClicked(true);
        if (mainContentRef.current) {
            window.scrollTo({
                top: mainContentRef.current.offsetTop,
                behavior: "smooth"
            });
        }
    };

    return (
        <article style={{ backgroundColor: '#FCFCFC' }}>

            <Helmet>

                <title>DSCR HELOC | LowRates</title>
                <meta name="description" content="LowRates, Mortgage Loans" />
                <meta name="keywords" content="LowRates, Mortgage, Loans, Swmc, SunWestMortgage, Mortgage Possible" />

            </Helmet>

            <div className={Classes.loanProgramDiv}>

                <img
                    src={imgCDNURL + "loanprogrambannerimage.webp"}
                    className={Classes.loanprogrambannerimage}

                />

                <img
                    src={imgCDNURL + "loanprogrambannerimagemobile.webp"}
                    className={Classes.loanprogrambannerimagemobile}

                />

                <Container className={Classes.loanProgramContainer}>
                    <a href="https://seemyloanstatus.com/ApplyNow/?c=lowrates" target="_blank" title="Click Here To ApplyNow">
                        <img
                            src={imgCDNURL + "applynowbanner.webp"}
                            className={Classes.applynowbanner}
                        />
                    </a>
                    <NavWrapper>
                        <Tab.Container id="left-tabs-example" activeKey={key}
                            onSelect={handleNavItemClick}>
                            <Row>
                                <Col md={12} lg={12} sm={12} className={Classes.fixedLeft}>
                                    <h4 className={Classes.leftHeader}>Loan Services</h4>
                                    <Row>
                                        <Col md={6} lg={6} sm={12} >
                                            <Nav variant="pills" className={Classes.setNavLeft}>
                                                <Nav.Item>
                                                    <Link eventKey={routeKeys.conventional} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink} onClick={(e) => {
                                                        if (e.ctrlKey) {
                                                            window.open("/conventional-loans", "_blank");
                                                        } else {
                                                            handleNavItemClick(routeKeys.conventional);
                                                            if (mainContentRef.current) {
                                                                window.scrollTo({
                                                                    top: mainContentRef.current.offsetTop,
                                                                    behavior: "smooth"
                                                                });
                                                            }
                                                        }
                                                    }} to="/conventional-loans">CONVENTIONAL LOANS</Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Link eventKey={routeKeys.fha} as={NavLink} to="/fha-loans" onClick={(e) => {
                                                        if (e.ctrlKey) {
                                                            window.open("/fha-loans", "_blank");
                                                        } else {
                                                            handleNavItemClick(routeKeys.fha);
                                                            if (mainContentRef.current) {
                                                                window.scrollTo({
                                                                    top: mainContentRef.current.offsetTop,
                                                                    behavior: "smooth"
                                                                });
                                                            }
                                                        }
                                                    }} activeClassName={Classes.activePillLink} className={Classes.pillLink}>FHA LOANS</Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Link eventKey={routeKeys.investment} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink} onClick={(e) => {
                                                        if (e.ctrlKey) {
                                                            window.open("/investment-loans", "_blank");
                                                        } else {
                                                            handleNavItemClick(routeKeys.investment);
                                                        }
                                                    }} to="/investment-loans">INVESTMENT PROPERTIES</Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Link eventKey={routeKeys.jumbo} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink} onClick={(e) => {
                                                        if (e.ctrlKey) {
                                                            window.open("/jumbo-loan", "_blank");
                                                        } else {
                                                            handleNavItemClick(routeKeys.jumbo);
                                                        }
                                                    }} to="/jumbo-loans">JUMBO LOANS</Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Link eventKey={routeKeys.renovation} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink1} onClick={(e) => {
                                                        if (e.ctrlKey) {
                                                            window.open("/renovation-loans", "_blank");
                                                        } else {
                                                            handleNavItemClick(routeKeys.renovation);
                                                        }
                                                    }} to="/renovation-loans">RENOVATION AND CONSTRUCTION LOANS</Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Col>
                                        <Col md={6} lg={6} sm={12} >
                                            <Nav variant="pills" className={Classes.setNavLeft}>
                                                <Nav.Item>
                                                    <Link eventKey={routeKeys.refinancing} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink} onClick={(e) => {
                                                        if (e.ctrlKey) {
                                                            window.open("/refinance", "_blank");
                                                        } else {
                                                            handleNavItemClick(routeKeys.refinancing);
                                                        }
                                                    }} to="/refinance">REFINANCING</Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Link eventKey={routeKeys.reverse} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink} onClick={(e) => {
                                                        if (e.ctrlKey) {
                                                            window.open("/reverse-mortgage", "_blank");
                                                        } else {
                                                            handleNavItemClick(routeKeys.reverse);
                                                        }
                                                    }} to="/reverse-mortgage">REVERSE MORTGAGE</Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Link
                                                        eventKey={routeKeys.va} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink} onClick={(e) => {
                                                            if (e.ctrlKey) {
                                                                window.open("/va-loans", "_blank");
                                                            } else {
                                                                handleNavItemClick(routeKeys.va);
                                                            }
                                                        }} to="/va-loans"
                                                    >
                                                        VA LOANS
                                                    </Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Link
                                                        eventKey={routeKeys.dscrheloc} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink2} onClick={(e) => {
                                                            if (e.ctrlKey) {
                                                                window.open("/dscrheloc", "_blank");
                                                            } else {
                                                                handleNavItemClick(routeKeys.dscrheloc);
                                                            }
                                                        }} to="/dscrheloc"
                                                    >
                                                        DSCR HELOC
                                                    </Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </NavWrapper>
                </Container>

                <div className={Classes.loanProgramContentDiv} ref={mainContentRef}>

                    <Container className={Classes.loanProgramContainer}>
                        <NavWrapper>
                            <Tab.Container id="left-tabs-example" activeKey={key}
                                onSelect={(k) => setKey(k)}>
                                <Row>

                                    <Col sm={12} lg={12} md={12} className={Classes.rightCol}>
                                        <h1 className={Classes.loanHeader}>
                                            {key === routeKeys.conventional && 'CONVENTIONAL LOANS'}
                                            {key === routeKeys.fha && 'FHA LOANS'}
                                            {key === routeKeys.investment && 'INVESTMENT PROPERTIES LOANS'}
                                            {key === routeKeys.jumbo && 'JUMBO LOANS'}
                                            {key === routeKeys.renovation && 'RENOVATION & CONSTRUCTION'}
                                            {key === routeKeys.refinancing && 'REFINANCING'}
                                            {key === routeKeys.reverse && 'REVERSE MORTGAGE / HECM LOANS'}
                                            {key === routeKeys.va && 'VA LOANS'}
                                            {key === routeKeys.dscrheloc && 'DSCR HELOC'}
                                        </h1>

                                        <div className={Classes.conventional}>

                                            <Tab.Pane eventKey={routeKeys.dscrheloc}>
                                                <img
                                                    src={imgCDNURL + "dscrhelocimage.webp"}
                                                    className={Classes.loanpurposeimage}
                                                    alt="DSCR HELOC image"
                                                />
                                                <h3>What is DSCR HELOC?</h3>
                                                <p>
                                                    Debt Service Coverage Ratio (DSCR) Home Equity Line of Credit (HELOC) is a financial product tailored specifically for property investors - seasoned and first-time investors eligible. DSCR HELOC allows investors to leverage their rental income to secure a revolving line of credit.
                                                </p>
                                                <h3>Advantages</h3>
                                                <ul>
                                                    <li><b>Rental Income Qualification:</b> Qualify based on your property’s rental income, not your personal finances</li>
                                                    <li><b>Revolving Line of Credit:</b> Borrow what you need and when you need it during the draw period</li>
                                                    <li><b>Accessible:</b> Competitive interest rates compared to credit cards or personal loans</li>
                                                </ul>
                                                <p>This is a great option for any person looking to invest in property to access cash for any business related expenses and business purpose</p>

                                                <h3>Program Highlights</h3>
                                                <ul>
                                                    <li>Maximum loan amount: $3 Million</li>
                                                    <li>Qualifying income is the subject property’s DSCR*</li>
                                                    <li>Minimum FICO: 700</li>
                                                    <li>30-Year variable term with 3-year draw period</li>
                                                    <li>No minimum DSCR</li>
                                                    <li>Maximum Loan-to-Value (LTV): 75%</li>
                                                    <li>First-Time investors allowed</li>
                                                    <li>Eligible properties: 1-4 Unit properties, Planned Unit Developments (PUDs), warrantable condominiums</li>
                                                    <li>Non-Permanent resident alien allowed</li>
                                                    <li>Vesting in the name of Limited Liability Company (LLC), partnership, and corporations allowed</li>
                                                </ul>
                                                <h3>Ready to Unleash Your Investment Potential?</h3>
                                                <p>Don't let your hard-earned equity collect dust. Turn it into your next big opportunity today!</p>
                                            </Tab.Pane>

                                        </div>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </NavWrapper>

                    </Container>
                </div>
            </div>
            <Container>
                <p className={Classes.dscr_discalimers}>*DSCR refers to Debt Service Coverage Ratio. It is the ratio of the property’s rental income and the Principal, Interest, Taxes, Insurance, and Association dues (PITIA) payment.
                </p>
                <p className={Classes.dscr_discalimers}>
                    Being High Risk Loans, Non-QM loans may include higher interest rates, closing costs, interest-only periods, or prepayment penalties.
                </p>
            </Container>

            <div className={Classes.loanhowitworksDiv}>

                <Container className={Classes.loanhowitworksContainer}>
                    <HowItWorks />
                </Container>
            </div>

            <div className={Classes.loanresourcesDiv}>

                <Container className={Classes.loanresourcesContainer}>
                    <ResourcesMenu />
                </Container>
            </div>
        </article>
    )
}

export default LoanPrograms