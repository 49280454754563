import React, { useState, useEffect, useRef } from 'react'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavDropdown } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { NavLink } from 'react-router-dom';
import { Link, useLocation } from 'react-router-dom';
import Classes from './index.module.css';
import { capitalizeFirstLetter, isMobileOrTablet } from "../Utils";
import styled from 'styled-components';
import { IoHomeSharp } from 'react-icons/io5';
import UserWayAccessibilityMenu from "../UserWayAccessibilityMenu/index.js";
import MediaQuery from "react-responsive";

const NavWrapper = styled.div`
.navbar-dark .navbar-nav .nav-link{
    color: #4f4f4f;
    z-index: 1200;
    font-weight: 600;
    font-size: 15px;
    margin-right: 40px;

    @media (min-width: 991px) and (max-width: 1200px) {
        margin-right: 20px !important;
      }
}
.navbar {
    background: #ffffff !important;
    transition: transform 0.3s ease-in, background 0.3s ease-in;
    will-change: transform;
}
.navbar.active {
    background: #ffffff !important;
}
.navbar.hidden {
    transform: translateY(-100%);
}
@media screen and (max-width: 991px) {
    .navbar-collapse.show {
        max-height: calc(100vh - 70px);
        overflow-y: auto;
    }
}
.navbar-dark .navbar-nav .nav-link:hover{
    color: #0054a6;
    
    @media (min-width: 320px) and (max-width:992px) {
        border-bottom: none !important;
    }
}
.navbar-dark .navbar-nav .nav-link.active{
    color:#0054a6;
    border-bottom: 4px solid #0054a6;

    @media (min-width: 320px) and (max-width:992px) {
        border-bottom: none !important;
    }
}
.navbar-expand-lg .navbar-nav .dropdown-menu{
    margin: 0px 0px 0px -30px !important;

    @media (min-width: 320px) and (max-width:992px) {
        margin: 0px 0px 0px 0px !important;
    }

    @media (min-width: 992px) and (max-width: 1024px) {
        margin: 0px 0px 0px -40px !important;
    }
}

.Navbar_ratesButton__2hEOQ.nav-link {
    color: #fff !important;
}

._3yQ6ipwnXEJX5uUyBeOBFR.btn.btn-primary.btn-md {
    font-weight: 600;
}

.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: none;
    border-color:white;
}
.btn-primary:focus {
    border-color: white;
}
.btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    background: #fff;
    color: #0481b5;
    border:1px solid #fff;
    box-shadow:none;
}
.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus{
    background: #fff;
    color: #0481b5;
    border:1px solid #fff;
    box-shadow:none;
}

.channelsNav{
    .dropdown-submenu-container a.dropdown-submenu::after {
        position: absolute;
        right: 0;
        top: 0;
    }
    .dropdown-menu{
        .dropdown-toggle::after {
            content: ">";
            vertical-align: right;
            border: none;
            margin-top: 4px;
            font-weight: 400;
            color: #dddd;
            transform: rotate(0) !important;
        }
        padding-right:15px !important;
    }
}

@media(min-width:992px) and (max-width:1200px){
    .nav-link {
        font-size: 14px !important;
    }
}
@media(max-width:992px){
    .navbar-toggler {
        padding: .25rem .75rem;
        font-size: 1.25rem;
        line-height: 1;
        color: #fff;
        background-color: #0054a6;
        border: 1px solid transparent;
        border-radius: .25rem;
    }
    .nav-link {
        font-size: 14px !important;
    }
    #responsive-navbar-nav {
        background-color: white;
        padding: 20px 10px;
        overflow-y: auto;
        max-height: calc(100vh - 70px);
    }
}
.dropdown-menu {
    background: #1f2127 !important;
    color: #fff !important;
    width: 200px;
    border-radius: none !important;
    top: 100%;
    font-size: 13px;
    line-height: 26px;
    font-weight: 400;
    padding: 0px 0px;
    @media (min-width: 320px) and (max-width:991.7px) {
        background: #0054a6 !important;
    }
}

.dropdown-item:last-child {
    border-bottom: 0!important;
    border-radius: none !important;
}

.dropdown-item {
    font-size: 13px;
    padding: 7px 24px !important;
    color: #ffffff !important;
    font-weight: 400;
    line-height: 26px;
    border-bottom: 0.5px solid #cccccc !important;
    white-space: normal;
}
.dropright .dropdown-menu {
    padding-left: 0;
    top: 40%;
}
.dropdown-item:hover, .dropdown-item:focus {
    background-color: #0054a6 !important; 
    color: #fff !important;
}
@media only screen and (max-width: 1298.97px) {
    .dropdown-menu {
        width: 200px;
    
    @media (min-width: 320px) and (max-width:992px) {
        width: 100% !important;
      }
    }
}
`;

const Navigation = (props) => {
    const [logoImage, setLogoImage] = useState('');
    const [navbar, setNavbar] = useState(false);
    const [navbarHidden, setNavbarHidden] = useState(false);
    const [isNavExpanded, setIsNavExpanded] = useState(false);
    const [showDropdown1, setShowDropdown1] = useState(false);
    const [showDropdown2, setShowDropdown2] = useState(false);
    const [showDropdown3, setShowDropdown3] = useState(false);
    const isMobileOrTabletDevice = isMobileOrTablet();
    const navbarRef = useRef(null);
    
    const imgCDN = process.env.REACT_APP_LOWRATES_RESOURCES;

    // Set logo image on component mount
    useEffect(() => {
        setLogoImage(imgCDN + "logo_03.png");
    }, []);

    // Close navbar on resize or route change
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 991 && isNavExpanded) {
                setIsNavExpanded(false);
            }
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [isNavExpanded]);

    // Handle scroll behavior
    useEffect(() => {
        let lastScrollTop = 0;
        const scrollThreshold = 10; // Minimum scroll before navbar hides/shows
        
        const handleScroll = () => {
            // Don't hide navbar when menu is expanded
            if (isNavExpanded) {
                setNavbarHidden(false);
                return;
            }
            
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            
            // Add background when scrolled
            if (scrollTop > 0) {
                setNavbar(true);
            } else {
                setNavbar(false);
            }
            
            // Only for mobile - hide navbar when scrolling down
            if (isMobileOrTabletDevice) {
                if (Math.abs(lastScrollTop - scrollTop) <= scrollThreshold) {
                    return; // Don't do anything if scroll is minimal
                }
                
                if (scrollTop > lastScrollTop && scrollTop > 100) {
                    // Scrolling down & past header - hide navbar
                    setNavbarHidden(true);
                } else {
                    // Scrolling up - show navbar
                    setNavbarHidden(false);
                }
            }
            
            lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
        };
        
        window.addEventListener('scroll', handleScroll, { passive: true });
        
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isNavExpanded, isMobileOrTabletDevice]);

    // Prevent body scroll when mobile menu is open
    useEffect(() => {
        if (isNavExpanded && isMobileOrTabletDevice) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
        
        return () => {
            document.body.style.overflow = '';
        };
    }, [isNavExpanded, isMobileOrTabletDevice]);

    // Dropdown handlers
    const handleDropdownToggle = (dropdownNum) => {
        if (dropdownNum === 1) setShowDropdown1(!showDropdown1);
        if (dropdownNum === 2) setShowDropdown2(!showDropdown2);
        if (dropdownNum === 3) setShowDropdown3(!showDropdown3);
    };

    // Close all dropdowns when one is clicked on mobile
    const handleMenuItemClick = () => {
        setIsNavExpanded(false);
    };

    // Toggle mobile menu
    const toggleMenu = () => {
        setIsNavExpanded(!isNavExpanded);
    };

    return (
        <NavWrapper>
            <Navbar 
                id="Navbar" 
                ref={navbarRef}
                collapseOnSelect 
                expand="lg" 
                fixed="top" 
                variant="dark" 
                className={`navbar ${navbar ? 'active' : ''} ${navbarHidden ? 'hidden' : ''}`} 
                expanded={isNavExpanded}
            >
                <Container>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={toggleMenu} />
                    <Navbar.Brand>
                        <NavLink to={{ pathname: '/', params: '' }}>
                            <div className={Classes.main_logo_div}>
                                <img
                                    src={logoImage}
                                    className={Classes.main_logo} 
                                    height="auto" 
                                    alt="lowrates-logo" 
                                />
                            </div>
                        </NavLink>
                    </Navbar.Brand>
                    
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="ms-auto">
                            <Nav.Link 
                                as={NavLink} 
                                to='/' 
                                onClick={handleMenuItemClick}
                            >
                                <IoHomeSharp style={{ marginRight: '10px', fontSize: '12px', marginTop: '-4px' }} />
                                Home
                            </Nav.Link>
                            
                            <Nav.Link 
                                as={NavLink} 
                                to='/about-us' 
                                onClick={handleMenuItemClick}
                            >
                                About Us
                            </Nav.Link>
                            
                            <NavDropdown 
                                title="Loan Programs" 
                                id="loanPrograms" 
                                show={showDropdown1}
                                onMouseEnter={() => !isMobileOrTabletDevice && setShowDropdown1(true)}
                                onMouseLeave={() => !isMobileOrTabletDevice && setShowDropdown1(false)}
                                onClick={() => handleDropdownToggle(1)}
                            >
                                <NavDropdown.Item as={Link} to="/conventional-loans" onClick={handleMenuItemClick}>Conventional Loans</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/fha-loans" onClick={handleMenuItemClick}>FHA Loans</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/investment-loans" onClick={handleMenuItemClick}>Investment Properties</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/jumbo-loans" onClick={handleMenuItemClick}>Jumbo Loans</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/renovation-loans" onClick={handleMenuItemClick}>Renovation And Construction Loans</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/refinance" onClick={handleMenuItemClick}>Refinancing</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/reverse-mortgage" onClick={handleMenuItemClick}>Reverse Mortgage</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/va-loans" onClick={handleMenuItemClick}>VA Loans</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/dscrheloc" onClick={handleMenuItemClick}>DSCR HELOC</NavDropdown.Item>
                            </NavDropdown>
                            
                            <NavDropdown 
                                title="Resources" 
                                id="resources" 
                                show={showDropdown2}
                                onMouseEnter={() => !isMobileOrTabletDevice && setShowDropdown2(true)}
                                onMouseLeave={() => !isMobileOrTabletDevice && setShowDropdown2(false)}
                                onClick={() => handleDropdownToggle(2)}
                            >
                                <NavDropdown.Item as={Link} to="/home-buying" onClick={handleMenuItemClick}>Home Buyer's Guide</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/refinancing-guide" onClick={handleMenuItemClick}>Refinancing Guide</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/documents" onClick={handleMenuItemClick}>Documents Needed</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="https://www.swmc.com/angelai/calculators" target='_blank' onClick={handleMenuItemClick}>Mortgage Calculators</NavDropdown.Item>
                            </NavDropdown>
                            
                            <NavDropdown 
                                title="Talk To Us" 
                                id="talkToUs" 
                                show={showDropdown3}
                                onMouseEnter={() => !isMobileOrTabletDevice && setShowDropdown3(true)}
                                onMouseLeave={() => !isMobileOrTabletDevice && setShowDropdown3(false)}
                                onClick={() => handleDropdownToggle(3)}
                            >
                                <NavDropdown.Item as={Link} to="/faq" onClick={handleMenuItemClick}>FAQ</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/contact" onClick={handleMenuItemClick}>Contact Us</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="https://www.swmc.com/ApplyNow/?c=lowrates" onClick={handleMenuItemClick}>Apply Now</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                    
                    <MediaQuery minWidth={768}>
                        <UserWayAccessibilityMenu color="black" width={50} height={50} />
                    </MediaQuery>
                </Container>
            </Navbar>
        </NavWrapper>
    );
};

export default Navigation;