import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import rootReducer from "./store/reducers";
import { legacy_createStore as createStore } from 'redux';
import { Provider } from "react-redux";
import Navbar from './components/Navbar';
import Main from './containers/App/index';
import LowRatesFooter from './components/LowratesFooter';
import axios from 'axios';
import { CookieSetting } from "cookiesetting-component";

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

// Enhanced logging function with better debugging capabilities
function logLocationIssue(message = "Unknown location issue", isError = false) {
  if (isError) {
    console.error("Location Error:", message);
  } else {
    console.warn("Location Info:", message);
  }
  
  // Log additional debugging information in development
  if (process.env.NODE_ENV === 'development') {
    console.debug("Environment variables:");
    console.debug("- REACT_APP_MAXMIND:", process.env.REACT_APP_MAXMIND);
    console.debug("- REACT_APP_SUNSOFT_RESTSERVER_ZIPCODE:", process.env.REACT_APP_SUNSOFT_RESTSERVER_ZIPCODE);
    console.debug("- REACT_APP_BASENAME:", process.env.REACT_APP_BASENAME);
    console.debug("- REACT_APP_REDIRECTION_URL:", process.env.REACT_APP_REDIRECTION_URL);
  }
}

function App() {
  useEffect(() => {
    let redirected = false;
    
    const fetchData = async () => {
      try {
        // Step 1: Get user IP
        const responseIpify = await axios.get("https://api.ipify.org", { timeout: 5000 });
        const userIP = responseIpify.data;
        
        if (!userIP) {
          console.warn("User IP not available");
          return;
        }
        
        // Step 2: Get location data from Maxmind
        const responseMaxmind = await axios.post(
          process.env.REACT_APP_MAXMIND,
          { ip: userIP },
          { headers: { "Content-Type": "application/json" }, timeout: 5000 }
        );
        
        const maxmindData = responseMaxmind.data;
        const redirectionUrl = process.env.REACT_APP_REDIRECTION_URL;
        
        // Handle different Maxmind response types
        if (maxmindData === "IP Not Available" || maxmindData === "Invalid Country") {
          // This is expected behavior for users outside the US
          console.log("Geolocation info:", maxmindData);
          return; // No redirection needed for users outside supported regions
        }
        
        if (!maxmindData || typeof maxmindData !== 'object') {
          console.warn("Invalid maxmind data format:", JSON.stringify(maxmindData));
          return;
        }
        
        // Step 3: Check for zipcode
        const userZipcode = maxmindData.zipcode;
        if (!userZipcode) {
          console.log("User zipcode not available in maxmind data");
          return;
        }
        
        // Step 4: Get state from zipcode
        try {
          const responseZipcode = await axios.get(
            process.env.REACT_APP_SUNSOFT_RESTSERVER_ZIPCODE + userZipcode,
            { headers: { "Content-Type": "application/json" }, timeout: 5000 }
          );
          
          if (!responseZipcode.data || !responseZipcode.data.state) {
            console.log("Invalid zipcode response format");
            return;
          }
          
          // Step 5: Redirect if necessary
          const userState = responseZipcode.data.state;
          const basename = process.env.REACT_APP_BASENAME;
          
          if (basename === "/" && userState === 'NY') {
            window.location.href = redirectionUrl;
            redirected = true;
          } else {
            console.log("No redirection needed for state:", userState);
          }
        } catch (zipcodeError) {
          console.warn("Error fetching zipcode data:", zipcodeError.message);
        }
      } catch (error) {
        console.error("Error in geolocation process:", error);
      }
    };
    
    fetchData();
  }, []);
  
  const basename = process.env.REACT_APP_BASENAME;
  
  return (
    <>
      <Router basename={basename} future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
        <Navbar />
        <Main />
        <LowRatesFooter />
      </Router>
      <CookieSetting 
        privacyPolicyLink={process.env.REACT_APP_PRIVACY_POLICY_LINK} 
        configUrl={process.env.REACT_APP_COOKIE_SETTING_JSON}
        analytics={process.env.REACT_APP_ANALYTICS_PATH}
      />
    </>
  );
}

export default App;